<template>
  <div class="price-tooltip">
    <I18nT keypath="header.profileTooltip">
      <template #coinImg1>
        <UiImage v-if="eventStore.coinIcon" :src="eventStore.coinIcon" class="price-tooltip__icon" />
      </template>
      <template #coinImg2>
        <UiImage v-if="eventStore.coinIcon" :src="eventStore.coinIcon" class="price-tooltip__icon" />
      </template>
      <template #coinImg3>
        <UiImage v-if="eventStore.coinIcon" :src="eventStore.coinIcon" class="price-tooltip__icon" />
      </template>
    </I18nT>
  </div>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';

const eventStore = useEventStore();
</script>

<style scoped src="./TooltipDescriptionCoin.scss"></style>
